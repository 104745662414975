import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, inBrowser } from '../pages';

type Data = {
  markdownRemark: {
    id: string;
    excerpt: string;
    html: string;
    frontmatter: {
      title: string;
      date: string;
      description?: string;
      tags?: string;
    };
  };
};

export default function Post({ data }: { data: Data }) {
  const post = data.markdownRemark;

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title} | Dilraj</title>
      </Helmet>

      <div className="blog-post-page">
        <h1
          className="display-4 pb-5"
          dangerouslySetInnerHTML={{ __html: post.frontmatter.title }}
        />

        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        {/*  Share */}
        <div className="mt-5 text-center">
          &emsp;&middot;&emsp;&emsp;&middot;&emsp;&emsp;&middot;&emsp;
        </div>
        {inBrowser && (
          <div className="mt-2 mb-5">
            <a
              className="animated"
              href={`http://twitter.com/share?text=${post.frontmatter.title}&url=${document.location.href}&hashtags=${post.frontmatter.tags}`}
            >
              tweet
            </a>
            <NativeShare
              title={post.frontmatter.title}
              url={document.location.toString()}
            />
          </div>
        )}
      </div>
    </Layout>
  );
}

const NativeShare = ({ title, url }: { title?: string; url: string }) => {
  //@ts-ignore
  if (!navigator?.share) return null;

  const shareTo = async (e: React.MouseEvent) => {
    e.preventDefault();

    try {
      //@ts-ignore
      await navigator.share({ title, url });
      console.log('shared!');
    } catch (e) {
      if (e.name === 'AbortError') console.log('Share aborted');
      else console.log(e);
    }
  };

  return (
    <a color="link" className="animated ml-2" href="/" onClick={shareTo}>
      share
    </a>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        tags
      }
    }
  }
`;
